import React, { useMemo } from 'react';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import Badge from 'antd/es/badge';
import useSelector from '../../utils/hooks/useSelector';
import { COMMON_EXCLUDED_FOR_MANAGER } from '../../utils/constants';

interface MenuProps {
  children?: any,
  title?: string,
  to?: string,
  counter?: number,
  component?: React.ReactNode,
  width?: number,
  offset?: [number | string, number | string]
}

const renderMenu = (menuItem: MenuProps) => {
  if (menuItem.component) return menuItem.component;

  const wrap = (el: React.ReactNode) => {
    if (typeof menuItem.counter === 'number') {
      return (
        <Badge count={menuItem.counter} overflowCount={999} offset={menuItem.offset}>
          {el}
        </Badge>
      );
    }
    return el;
  };

  if (menuItem.children && menuItem.children.length) {
    return (

      <Menu.SubMenu
        title={wrap(menuItem.title)}
        className="ant-menu-submenu ant-menu-submenu-horizontal gx-menu-horizontal"
      >
        {menuItem.children.map((item: MenuProps) => (
          <Menu.Item key={item.to}>
            <Link className="ant-menu-submenu-title" to={item.to || '/'}>
              {item.title}
            </Link>
          </Menu.Item>
        ))}
      </Menu.SubMenu>
    );
  }
  return (
    <Menu.Item className="ant-menu-submenu ant-menu-submenu-horizontal gx-menu-horizontal">
      <Link
        className="ant-menu-submenu-title"
        to={menuItem.to || '/'}
      >
        {wrap(menuItem.title)}
      </Link>
    </Menu.Item>
  );
};

function HorizontalNav() {
  const { globalCounters } = useSelector((state) => state.counter);
  const { user } = useSelector((state) => state.users);

  const menu: Array<MenuProps> = [
    {
      title: 'Заказы',
      to: '/waybills',
    },
    {
      title: 'Контроль 360',
      to: '/control_360',
    },
    {
      title: 'Страховки',
      to: '/insurance',
    },
    // {
    //   title: 'Задачи',
    //   to: '/attention?status=active',
    //   counter: globalCounters.attentions,
    // },
    {
      title: 'Заявки',
      to: '/claim?status=new',
      counter: globalCounters.activeClaims,
      offset: [10, 0],
    },
    {
      title: 'Обращения',
      to: '/chat',
      children: [
        {
          title: 'Обращения',
          to: '/chat',
          counter: globalCounters.activeDialogs,
        },
        {
          title: 'Уведомление клиентов',
          to: '/notifications/global',
        },
      ],
      counter: globalCounters.activeDialogs,
      offset: [10, 0],
    },
    {
      title: 'Тарифы',
      to: '/rates',
      children: [
        {
          title: 'Тарифы',
          to: '/rates',
        },
        {
          title: 'Маркетинг',
          to: '/rates/marketing',
        },
        {
          title: 'Ключи',
          to: '/operator/keys',
        },
        {
          title: 'Сбор на сегодня',
          to: '/operator/scheduler',
        },
        {
          title: 'Приоритет тарифов тендера',
          to: '/tender/rates',
        },
        {
          title: 'Скидки за забор',
          to: '/rates/cart/marketing',
        },
        {
          title: 'Геополигоны для служб',
          to: '/operator/geo',
        },
        {
          title: 'Apiship',
          to: '/service/apiship',
        },
        {
          title: 'Фейковые тарифы',
          to: '/fake_service',
        },
        {
          title: 'Кешбэк',
          to: '/rates_cashback',
        },
        {
          title: 'Дополнительные услуги',
          to: '/additional_service',
        },
      ],
    },
    {
      title: 'Отчеты',
      to: '/report',
      children: [
        {
          title: 'Отчет по статусам',
          to: '/report',
        },
        {
          title: 'Отчет о сроках',
          to: '/processing',
        },
        {
          title: 'СДЭК',
          to: '/dashboard/cdek',
        },
        {
          title: 'CSE',
          to: '/dashboard/cse',
        },
        {
          title: 'SLA первого ответа',
          to: '/statistic/chat',
        },
        {
          title: 'Отчет по контрольным статусам',
          to: '/collision_report/summary',
        },
        {
          title: 'Контрольные статусы CDEK',
          to: '/collision_report/cdek',
        },
        {
          title: 'Контрольные статусы CSE',
          to: '/collision_report/cse',
        },
      ],
    },
    {
      title: 'Международка',
      offset: [10, 0],
      counter: globalCounters.activeForeignInvoices,
      to: '/foreign_invoice?status=new',
      children: [
        {
          title: 'Инвойсы',
          to: '/foreign_invoice?status=new',
          offset: [10, 0],
        },
        {
          title: 'Страны',
          to: '/countries',
        }
      ]
    },
    {
      title: 'Телефоны',
      to: '/phones',
    },
    {
      title: 'Терминалы',
      to: '/terminals',
    },
    {
      title: 'Отзывы',
      to: '/dialog_reviews',
    },
  ];

  const filteredMenu = useMemo(() => {
    return menu.filter(item => {
      if (user && user.isManager && !user.isAdmin && item.to) {
        return !COMMON_EXCLUDED_FOR_MANAGER.includes(item.to);
      }
      return true
    })
  }, [user])

  return (
    <Menu
      className="ant-menu ant-menu-light ant-menu-root ant-menu-horizontal"
      mode="horizontal"
    >
      {filteredMenu.map((item) => renderMenu(item))}
    </Menu>
  );
}

export default HorizontalNav;
